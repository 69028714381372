@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,700;1,800&family=Poppins&family=Quicksand:wght@300;400;500;600;700&display=swap');

/*.custom-cursor{
  cursor: url(../public/cursor.png),auto !important;
}*/
.w-fill-av {
  width: -webkit-fill-available !important;
}
.h-fill-av {
  height: -webkit-fill-available !important;
}
.font-josef{
  font-family: 'Josefin Sans', sans-serif;
}

.font-sand{
  font-family: 'Quicksand', sans-serif;
}
.font-ops{
  font-family: 'Open Sans', sans-serif;
}
.font-monta{
  font-family: 'Montserrat', sans-serif;
}
.font-pop{
  font-family: 'Poppins', sans-serif;
}
  #nav-bar nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .hero-background{
    background-image: url('../public/background.png');
  }
  
  #nav-bar  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  #nav-bar ul,
  #nav-bar li {
    margin: 0;
    padding: 0;
  }

  #nav-bar li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  

  #nav-bar .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  

  @layer base {
    .font-outline-1 {
      -webkit-text-stroke: 0.5px rgb(46, 213, 255);
    }
    .shadow-nav-item{
        text-shadow:0px 0px 14px #2C8BD9, 0px 0px 8px #2C8BD9;
    }
    .font-outline-1-white {
        -webkit-text-stroke: 2px rgb(255, 255, 255);
      }
      .font-outline-01-white {
        -webkit-text-stroke: 1px rgb(255, 255, 255);
      }
      .font-outline-1-C1 {
        -webkit-text-stroke: 2px #2C8BD9;
      }
      .font-outline-1-red {
        -webkit-text-stroke: 2px #d92c2c;
      }
      .blur-99xl{

    filter: blur(170.1px);

      }
      .w-99{
        width: 760px;
      }
      .w-77{
        width: 275px;
      }
      .box-shadow-black{
        box-shadow: inset 0px -37px 67.5px #000000;
      }
  }
.product-section-shadow{
 
  box-shadow: 0px 0px 4px rgba(237, 237, 237, 0.929412);
  border-radius: 24px;

}
.no-scroll {
  overflow: hidden;
}

.bg-packs-section{
  background-image: url('../public/background-section.png');

}
.bg-text-image{
  background-image: url('../public/background-text-image-section.png');

}

 